import { Layout } from "@components/Layout"
import { updateIsAcceptStatement } from "@redux/actions"
import { currentBasketSelector } from "@redux/selectors/basketSelector"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector"
import React from "react"
import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from "redux"
import { mapToSEO, SEO } from "@components/SEO"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { Grid } from "semantic-ui-react"
import OrderSummary from "@components/OrderSummary/OrderSummary"
import DealerStatementSection from "@components/Dealer/DealerStatementSection"
import { graphql } from "gatsby"

const DealerStatementPageContainer = (props: any) => {
    const { elements } = props.data.kontentItemDealerStatementPage;
    const seo = mapToSEO(elements);
    const apiUrl = props.data.site.siteMetadata.apiUrl;

    return (
        <Layout version="simple" currentStep={3} location={props.location}>
            <SEO {...seo} />
            <GenericBannerContainer
                padding={{
                mobile: {
                    top: 20,
                    bottom: 40
                },
                desktop: {
                    top: 40,
                    bottom: 60
                }
                }}
                backgroundColor={theme.brand.colors.beige} paddingBottom={60} paddingTop={40}>
                <Grid stackable reversed='mobile tablet vertically' stretched>
                <Grid.Column mobile={16} tablet={16} computer={10}>
                    <DealerStatementSection
                        title={elements.title.value}
                        content={elements.content.value}
                        checkout={props.checkoutState}
                        updateIsAcceptStatement={props.updateIsAcceptStatement}/>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6} floated='right'>
                    <OrderSummary isLayby={false} showSurcharge={false} basket={props.basket} apiUrl={apiUrl} giftType={props.checkoutState.giftingType}/>
                </Grid.Column>
                </Grid>
            </GenericBannerContainer>
        </Layout>
    )
}

const mapStateToProps = (state: Record<any>) => {
    const checkout = currentCheckoutSelector(state).toJS();
    const basket = currentBasketSelector(state).toJS();

    return {
      checkoutState: checkout,
      basket: basket
    };
  }

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateIsAcceptStatement(isAcceptStatement: boolean) {
      dispatch(updateIsAcceptStatement(isAcceptStatement));
    }
  })

const DealerStatementPage = connect(mapStateToProps, mapDispatchToProps)(DealerStatementPageContainer)

export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemDealerStatementPage {
    elements {
      title {
        value
      }
      content {
        value
      }
      seo__nofollow {
        value {
          codename
          name
        }
      }
      seo__noindex {
        value {
          codename
          name
        }
      }
      seo__page_description {
        value
      }
      seo__page_title {
        value
      }
      url{
        value
      }
    }
  }
}
  `


export default DealerStatementPage;